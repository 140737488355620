import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card, CardContent, CardHeader, Typography, Button, TextField, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, IconButton, Divider, Box, Grid, Snackbar, Collapse, Checkbox, FormControlLabel } from '@mui/material';
import { Book, Headphones, Check, Delete, Visibility, Refresh, ExpandMore, ExpandLess, PlayArrow, Pause } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { AppBar, Toolbar, Badge, useMediaQuery } from '@mui/material';
import { EmojiEvents, LibraryBooks, ExitToApp, AccountCircle, ArrowForward, ArrowBack  } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { RecordVoiceOver, VoiceOverOff } from '@mui/icons-material';

const config = {
  apiUrl: 'https://wordjourney.harunhurma.com/wordlist_backend'
};


const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

// Özelleştirilmiş LinearProgress bileşeni
const ColoredLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${LinearProgress.colorPrimary}`]: {
    backgroundColor: '#d3d3d3',
  },
  [`& .${LinearProgress.bar}`]: {
    borderRadius: 5,
    background: 'linear-gradient(90deg, #4caf50 50%, #3f51b5 50%)',
  },
}));

// Öğrenilen kelimeler ve kalan kelimeler için LinearProgress bileşeni
const LearnedProgressBar = ({ value }) => {
  const learnedValue = Math.min(value, 100);
  const remainingValue = 100 - learnedValue;

  return (
    <Box position="relative">
      <ColoredLinearProgress variant="determinate" value={learnedValue} />
      <Box
        position="absolute"
        top={0}
        left={`${learnedValue}%`}
        height="100%"
        width={`${remainingValue}%`}
        style={{ backgroundColor: '#4caf50' }}
      />
    </Box>
  );
};

const WordJourney = () => {
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [mode, setMode] = useState('learn');
  const [quizMode, setQuizMode] = useState('EN-TR');
  const [score, setScore] = useState(0);
  const [wordGoal, setWordGoal] = useState(20);
  const [learnedWords, setLearnedWords] = useState([]);
  const [quizOptions, setQuizOptions] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [open, setOpen] = useState(false);
  const [learningCompleted, setLearningCompleted] = useState(false);
  const [language, setLanguage] = useState('EN-TR');
  const [writingAnswer, setWritingAnswer] = useState([]);
  const [shuffledLetters, setShuffledLetters] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [reviewGroups, setReviewGroups] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [workingWithWrongAnswers, setWorkingWithWrongAnswers] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [disableLearnedButton, setDisableLearnedButton] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [shuffleWords, setShuffleWords] = useState(false); // Yeni state
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);  
  const [shuffleUpload, setShuffleUpload] = useState(false); // State'ler arasında shuffle seçeneğini ekleyin
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [totalLearnedWords, setTotalLearnedWords] = useState(0);
  const [showFullMeaning, setShowFullMeaning] = useState(false);
  const [isQuizActive, setIsQuizActive] = useState(false);
  const [globalLearnedWords, setGlobalLearnedWords] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isConversationMode, setIsConversationMode] = useState(false);
  const [availableVoices, setAvailableVoices] = useState([]);

  

  

  const currentWord = workingWithWrongAnswers 
    ? wrongAnswers[currentWordIndex] 
    : words[currentWordIndex] || null;

  const speechRef = useRef(null);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUploadedFiles();
      if (activeFile) {
        getProgress(activeFile);
      }
    }
  }, [isLoggedIn, activeFile]);

  useEffect(() => {
    const updateVoices = () => {
      setAvailableVoices(speechSynthesis.getVoices());
    };
    
    speechSynthesis.onvoiceschanged = updateVoices;
    updateVoices();

    return () => {
      speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  const normalizeLanguageCode = (langCode) => {
    return langCode.replace('_', '-').toLowerCase();
  };

  const getVoice = (langCodes, voiceNames, voiceURIs) => {
    const normalizedLangCodes = langCodes.map(normalizeLanguageCode);
    return availableVoices.find(voice => 
      normalizedLangCodes.some(code => normalizeLanguageCode(voice.lang).startsWith(code)) ||
      voiceNames.some(name => voice.name.toLowerCase().includes(name.toLowerCase())) ||
      voiceURIs.some(uri => voice.voiceURI === uri)
    );
  };

  useEffect(() => {
    if (mode === 'write' && currentWord) {
      shuffleLetters();
    }
  }, [mode, currentWord]);


  const togglePlayPause = () => {
    setIsPlaying(prev => !prev);
    if (isPlaying) {
      stopSpeaking();
    }
  };

  const toggleConversationMode = () => {
    setIsConversationMode(prev => !prev);
    stopSpeaking();
    if (isPlaying) {
      setIsPlaying(false);
    }
  };

    // Konuşmayı kontrol etmek için bir ref kullanalım
  
  
  useEffect(() => {
    let timer;
    const changeWord = async () => {
      if (isConversationMode && isPlaying) {
        await speakCurrentWord();
      }
      if (isPlaying) {
        nextWord();
        timer = setTimeout(changeWord, isConversationMode ? 1000 : 3000);
      }
    };

    if (isPlaying) {
      timer = setTimeout(changeWord, isConversationMode ? 1000 : 3000);
    }

    return () => {
      clearTimeout(timer);
      stopSpeaking();
    };
  }, [isPlaying, currentWordIndex, isConversationMode, currentWord, language]);


  const speakCurrentWord = async () => {
    if (currentWord && isConversationMode && isPlaying) {
      const turkishLangCodes = ['tr-TR', 'tr_TR'];
      const englishLangCodes = ['en-US', 'en_US'];
      const turkishVoiceNames = ['Türkçe Türkiye', 'Yelda'];
      const englishVoiceNames = ['İngilizce Amerika Birleşik Devletleri', 'Samantha'];
      const turkishVoiceURIs = ['com.apple.ttsbundle.Yelda-compact'];
      const englishVoiceURIs = ['com.apple.voice.compact.en-US.Samantha'];

      if (language === 'EN-TR') {
        await speak(currentWord.word, englishLangCodes, englishVoiceNames, englishVoiceURIs);
        if (!isPlaying) return;
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (!isPlaying) return;
        await speak(currentWord.meaning, turkishLangCodes, turkishVoiceNames, turkishVoiceURIs);
      } else {
        await speak(currentWord.meaning, turkishLangCodes, turkishVoiceNames, turkishVoiceURIs);
        if (!isPlaying) return;
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (!isPlaying) return;
        await speak(currentWord.word, englishLangCodes, englishVoiceNames, englishVoiceURIs);
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  };

  const speak = (text, langCodes, voiceNames, voiceURIs) => {
    return new Promise((resolve) => {
      const utterance = new SpeechSynthesisUtterance(text);
      const voice = getVoice(langCodes, voiceNames, voiceURIs);

      if (voice) {
        utterance.voice = voice;
        utterance.lang = voice.lang;
      } else {
        console.warn(`Voice for ${langCodes.join('/')} (${voiceNames.join(', ')}) not found. Using default voice.`);
      }

      utterance.onend = resolve;
      speechRef.current = utterance;
      speechSynthesis.speak(utterance);
    });
  };

  const stopSpeaking = () => {
    speechSynthesis.cancel();
    speechRef.current = null;
  };







  const fetchUploadedFiles = async () => {
    const token = localStorage.getItem('token'); // Token'ı local storage'dan alın
    try {
      const response = await axios.get(`${config.apiUrl}/list-files`, {
        headers: { 'Authorization': token }
      });
      setUploadedFiles(response.data);
    } catch (error) {
      setAlertMessage('Dosyalar alınamadı: ' + error.message);
      setAlertOpen(true);
    }
  };

  const fetchGroup = async (fileName, groupNumber) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${config.apiUrl}/file-group/${fileName}/${groupNumber}`, {
        headers: { 'Authorization': token }
      });
      let groupWords = response.data.filter(word => !word.learned);
      if (shuffleWords) {
        groupWords = shuffleArray(groupWords);
      }
      setWords(groupWords);
      setCurrentWordIndex(0);
      setCurrentGroup(groupNumber - 1);
      setDisableLearnedButton(false);
      
      if (groupWords.length === 0) {
        setAlertMessage('Bu grupta öğrenilecek yeni kelime kalmadı. Bir sonraki gruba geçebilirsiniz.');
        setAlertOpen(true);
      } else if (groupWords.length < 20) {
        setAlertMessage(`Bu grupta ${groupWords.length} kelime kaldı.`);
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Grup kelimeleri alınamadı:', error);
      setAlertMessage('Grup kelimeleri alınamadı.');
      setAlertOpen(true);
      setWords([]);
    }
  };
  
  
  

  const saveProgress = async (filename, group, learnedWords) => {
    const token = localStorage.getItem('token'); // Token'ı local storage'dan alın
    const payload = {
      filename,
      group,
      learnedWords: JSON.stringify(learnedWords) // JSON formatında gönder
    };
    console.log('Sending update progress request', payload);
    
    try {
      await axios.post(`${config.apiUrl}/update-progress`, payload, {
        headers: { 'Authorization': token }
      });
    } catch (error) {
      console.error('İlerleme kaydedilemedi:', error);
    }
  };
  
  
  
  
  

  const getProgress = async (fileName) => {
    try {
      const response = await axios.get(`${config.apiUrl}/get-progress/${fileName}`, {
        headers: { 'Authorization': token }
      });
      
      const { learnedWords, group } = response.data;
      
      // learnedWords bir dizi değilse, boş bir dizi kullan
      const parsedLearnedWords = Array.isArray(learnedWords) ? learnedWords : [];
  
      setLearnedWords(parsedLearnedWords);
      setTotalLearnedWords(parsedLearnedWords.length);
      setScore(parsedLearnedWords.length);
      setGlobalLearnedWords(totalLearnedWords || 0); // globalLearnedWords bilgisini set edin
  
      // group değeri geçerli bir sayı değilse 0 kullan
      const currentGroup = Number.isInteger(group) ? group : 0;
      setCurrentGroup(currentGroup);
  
      // Mevcut grubu yükle
      await fetchGroup(fileName, currentGroup + 1);
  
    } catch (error) {
      console.error('İlerleme bilgisi alınamadı:', error);
      setAlertMessage(`İlerleme bilgisi alınamadı: ${error.message}`);
      setAlertOpen(true);
  
      // Hata durumunda varsayılan değerleri ayarla
      setLearnedWords([]);
      setTotalLearnedWords(0);
      setScore(0);
      setCurrentGroup(0);
  
      // Yine de ilk grubu yüklemeye çalış
      await fetchGroup(fileName, 1);
    }
  };
  
    
  

  const saveLearnedWords = async (learnedWords, filename) => {
    const token = localStorage.getItem('token');
    if (!filename) return;
    try {
      console.log('Sending learned words to server:', learnedWords);
  
      const response = await axios.post(`${config.apiUrl}/save-learned-words`, {
        filename,
        learnedWords: learnedWords.map(word => ({ 
          word: word.word, 
          meaning: word.meaning, 
          sentence: word.sentence, 
          sentenceMeaning: word.sentenceMeaning 
        }))
      }, {
        headers: { 'Authorization': token }
      });
  
      console.log('Save learned words response:', response.data);
      setAlertMessage('Öğrenilen kelimeler kaydedildi.');
      setAlertOpen(true);
    } catch (error) {
      console.error('Öğrenilen kelimeler kaydedilemedi:', error);
      setAlertMessage('Öğrenilen kelimeler kaydedilemedi.');
      setAlertOpen(true);
    }
  };
  
  
  
  
  
  

  const nextWord = () => {
    if (words.length === 0) {
      setAlertMessage('Bu gruptaki tüm kelimeler öğrenildi.');
      setAlertOpen(true);
      return;
    }

    setCurrentWordIndex(prevIndex => {
      let nextIndex = prevIndex + 1;
      if (nextIndex >= words.length) {
        nextIndex = 0;
      }
      return nextIndex;
    });
    setWritingAnswer([]);
    setExpanded(false);
  };

  const previousWord = () => {
    let prevIndex = currentWordIndex - 1;
    if (prevIndex < 0) {
      prevIndex = words.length - 1;
    }
    while (prevIndex >= 0 && words[prevIndex].learned) {
      prevIndex--;
    }
    setCurrentWordIndex(prevIndex >= 0 ? prevIndex : 0);
    setExpanded(false);
  };

  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (uploadedFiles.some(uploadedFile => uploadedFile.name === file.name)) {
      setAlertMessage('Bu dosya zaten yüklenmiş.');
      setAlertOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('shuffle', shuffleUpload); // Shuffle seçeneğini formData'ya ekleyin
  
    const token = localStorage.getItem('token'); // Token'ı local storage'dan alın
  
    try {
      const response = await axios.post(`${config.apiUrl}/upload`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Authorization': token // Bearer token'ı eklediğinizden emin olun
        },
      });
      const newWords = response.data.file.content.split('\n').map(line => {
        const parts = line.split(';');
        if (parts.length === 6) {
          const [word, meaning, sentence, sentenceMeaning, learned, group] = parts;
          return { 
            word: word.trim(), 
            meaning: meaning.trim(), 
            sentence: sentence.trim(), 
            sentenceMeaning: sentenceMeaning.trim(), 
            learned: learned.trim() === '1', 
            group: parseInt(group.trim(), 10) 
          };
        }
        return null;
      }).filter(item => item !== null);
  
      setUploadedFiles([...uploadedFiles, { name: file.name, count: newWords.length, content: newWords }]);
      if (!activeFile) {
        fetchGroup(file.name, 1);
        setActiveFile(file.name);
        const learnedCount = newWords.filter(word => word.learned).length;
        setLearnedWords(newWords.filter(word => word.learned));
        const newGroups = [];
        for (let i = 0; i < learnedCount; i += 20) {
          newGroups.push({ group: (i / 20) + 1, words: newWords.slice(i, i + 20) });
        }
        setReviewGroups(newGroups);
        if (learnedCount === 20) {
          setOpen(true);
        }
      }
      setAlertMessage('Dosya başarıyla yüklendi.');
      setAlertOpen(true);
    } catch (error) {
      console.error('Dosya yükleme hatası:', error);
      const errorMessage = error.response ? error.response.data.message : error.message;
      setAlertMessage('Dosya yükleme hatası: ' + errorMessage);
      setAlertOpen(true);
    }
  }; 
  
  

  const fetchFileContent = async (fileName) => {
  try {
    const response = await axios.get(`${config.apiUrl}/files/${fileName}`, {
      headers: { 'Authorization': token }
    });
    const newWords = response.data.content.split('\n').map(line => {
      const parts = line.split(';');
      if (parts.length === 6) {
        const [word, meaning, sentence, sentenceMeaning, learned, group] = parts;
        return { 
          word: word.trim(), 
          meaning: meaning.trim(), 
          sentence: sentence.trim(), 
          sentenceMeaning: sentenceMeaning.trim(), 
          learned: learned.trim() === '1', 
          group: parseInt(group.trim(), 10) 
        };
      }
      return null;
    }).filter(item => item !== null);

    const learnedWords = newWords.filter(word => word.learned);
    setTotalLearnedWords(learnedWords.length);
    const learnedCount = learnedWords.length;
    const newGroups = [];
    

    for (let i = 0; i < learnedCount; i += 20) {
      newGroups.push({ group: (i / 20) + 1, words: learnedWords.slice(i, i + 20) });
    }

    setWords(newWords.filter(word => !word.learned));
    setActiveFile(fileName);
    setLearnedWords(learnedWords);
    //setGlobalLearnedWords(learnedWords.length);
    setReviewGroups(newGroups);
    setCurrentGroup(response.data.currentGroup);
    setCurrentWordIndex(0);
    setMode('learn');
    setLearningCompleted(false);
    setDisableLearnedButton(false); // Öğrendim butonunu etkinleştir
  } catch (error) {
    setAlertMessage('Dosya içeriği getirme hatası.');
    setAlertOpen(true);
  }
};

  
  

  const deleteFile = async (fileName) => {
    const token = localStorage.getItem('token'); // Token'ı local storage'dan alın
    try {
      await axios.delete(`${config.apiUrl}/files/${fileName}`, {
        headers: { 'Authorization': token }
      });
      setUploadedFiles(uploadedFiles.filter(file => file.name !== fileName));
      if (fileName === activeFile) {
        setWords([]);
        setActiveFile(null);
        setLearnedWords([]);
        setReviewGroups([]);
        setCurrentGroup(0);
        setCurrentWordIndex(0);
        setScore(0);
        setMode('learn');
        setLearningCompleted(false);
        setGlobalLearnedWords(0); // globalLearnedWords sayısını sıfırlayın
      }
      setAlertMessage('Dosya başarıyla silindi.');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage('Dosya silme hatası.');
      setAlertOpen(true);
    }
  };
  
  

  const resetLearnedWords = async (fileName) => {
    try {
      await axios.post(`${config.apiUrl}/reset-learned-words`, {
        filename: fileName
      }, {
        headers: { 'Authorization': token }
      });
      setAlertMessage('Öğrenilen kelimeler sıfırlandı.');
      setAlertOpen(true);
      setTotalLearnedWords(0);
      setGlobalLearnedWords(0); // globalLearnedWords sayısını sıfırlayın
      fetchFileContent(fileName);
    } catch (error) {
      setAlertMessage('Öğrenilen kelimeler sıfırlanamadı.');
      setAlertOpen(true);
    }
  };

  const selectFile = async (file) => {
    try {
      const response = await axios.get(`${config.apiUrl}/get-progress/${file.name}`, {
        headers: { 'Authorization': token }
      });
      const progress = response.data;
  
      const newLearnedWords = progress.learnedWords || [];
      const newGlobalLearnedWords = progress.totalLearnedWords || 0;
  
      setCurrentGroup(progress.group);
      setLearnedWords(newLearnedWords);
      setScore(newLearnedWords.length);
      //setGlobalLearnedWords(newGlobalLearnedWords);
      
      await fetchFileContent(file.name);
  
      fetchGroup(file.name, progress.group + 1);
      setLearnedWords(newLearnedWords);
      setScore(newLearnedWords.length);
      //setGlobalLearnedWords(newGlobalLearnedWords);
    } catch (error) {
      setAlertMessage('İlerleme bilgisi alınamadı.');
      setAlertOpen(true);
    }
  };
  

  const viewLearnedWords = async (fileName) => {
    try {
      const response = await axios.get(`${config.apiUrl}/files/${fileName}`, {
        headers: { 'Authorization': token }
      });
      const newWords = response.data.content.split('\n').map(line => {
        const parts = line.split(';');
        if (parts.length === 6) {
          const [word, meaning, sentence, sentenceMeaning, learned, group] = parts;
          return { 
            word: word.trim(), 
            meaning: meaning.trim(), 
            sentence: sentence.trim(), 
            sentenceMeaning: sentenceMeaning.trim(), 
            learned: learned.trim() === '1', 
            group: parseInt(group.trim(), 10) 
          };
        }
        return null;
      }).filter(item => item !== null);
  
      const learnedWords = newWords.filter(word => word.learned);
      setWords(learnedWords);
      setActiveFile(fileName);
    } catch (error) {
      setAlertMessage('Dosya içeriği getirme hatası.');
      setAlertOpen(true);
    }
  };

  const generateQuizOptions = () => {
    if (!currentWord) return;
  
    // Tüm kelimeleri bir araya getir (öğrenilmiş ve öğrenilmemiş)
    const allWords = [...words, ...learnedWords];
  
    // Eğer toplam kelime sayısı 4'ten azsa, uyarı göster ve quizi engelle
    if (allWords.length < 4) {
      setAlertMessage('Quiz için yeterli kelime yok. En az 4 kelime gerekli.');
      setAlertOpen(true);
      return;
    }
  
    // Kelime listesini loglayın
    console.log("All Words:", allWords);
  
    const options = [];
    const usedIndices = new Set();
  
    // Doğru cevabı ekle
    options.push(language === 'EN-TR' ? currentWord.meaning : currentWord.word);
  
    // 3 yanlış cevap ekle
    while (options.length < 4) {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * allWords.length);
      } while (usedIndices.has(randomIndex) || allWords[randomIndex] === currentWord);
  
      usedIndices.add(randomIndex);
      const randomWord = allWords[randomIndex];
      const option = language === 'EN-TR' ? randomWord.meaning : randomWord.word;
      options.push(option);
    }
  
    // Quiz seçeneklerini loglayın
    console.log("Quiz Options:", options);
  
    // Kullanılan indeksleri loglayın
    console.log("Used Indices:", usedIndices);
  
    setQuizOptions(shuffleArray(options));
  };
  

  const startQuiz = () => {
    const allWords = [...words, ...learnedWords];
    if (allWords.length < 4) {
      setAlertMessage('Quiz için yeterli kelime yok. En az 4 kelime gerekli.');
      setAlertOpen(true);
      return;
    }
    setMode('quiz');
    setCurrentWordIndex(0);
    generateQuizOptions();
    setIsQuizActive(true);  // Quiz başladığında bu state'i true yapıyoruz
  };

  const exitQuiz = () => {
    setMode('learn');
    setIsQuizActive(false);
  };

  const handleQuizAnswer = (answer) => {
    const correctAnswer = language === 'EN-TR' ? currentWord.meaning : currentWord.word;
    if (answer === correctAnswer) {
      setScore(score + 1);
    } else {
      setWrongAnswers([...wrongAnswers, currentWord]);
    }
    nextWord();
  };

  const markAsLearned = async () => {
    if (!learningCompleted && currentWord) {
      console.log('Current word to be marked as learned:', currentWord);
  
      // Mevcut kelimeyi güncellenmiş kelimelerden çıkar
      const updatedWords = words.filter(word => 
        word.word !== currentWord.word || word.meaning !== currentWord.meaning
      );
      setWords(updatedWords);
  
      try {
        // Yeni öğrenilen kelimeler dizisine mevcut kelimeyi ekle
        const newLearnedWords = [...learnedWords, currentWord];
        console.log('Updated learned words:', newLearnedWords);
        setLearnedWords(newLearnedWords);
        setTotalLearnedWords(prevTotal => prevTotal + 1);
        setGlobalLearnedWords(prevTotal => prevTotal + 1); // globalLearnedWords sayısını güncelle
  
        // İlerlemenin kaydedilmesi
        await saveProgress(activeFile, currentGroup, newLearnedWords);
        console.log('Progress saved for word:', currentWord.word);
  
        if (newLearnedWords.length === 20) {
          setReviewGroups(prevGroups => [...prevGroups, { group: currentGroup + 1, words: newLearnedWords }]);
          setOpen(true);
        } else {
          setCurrentWordIndex(prevIndex => {
            if (prevIndex >= updatedWords.length - 1) {
              return 0;
            }
            return prevIndex;
          });
        }
  
        // Öğrenilen kelimelerin kaydedilmesi
        await saveLearnedWords(newLearnedWords, activeFile);
        console.log('All learned words saved successfully');
  
      } catch (error) {
        console.error('Öğrenilen kelimeler kaydedilemedi:', error);
        setAlertMessage('Öğrenilen kelimeler kaydedilemedi.');
        setAlertOpen(true);
      }
    }
  };
  
  
  
  
  
  

  const handleClose = (continueJourney) => {
    setOpen(false);
    if (continueJourney) {
      const nextGroup = currentGroup + 1;
      fetchGroup(activeFile, nextGroup + 1);
      setCurrentGroup(nextGroup);
      setLearnedWords([]);
      setGlobalLearnedWords(globalLearnedWords);
      setCurrentWordIndex(0);
      setLearningCompleted(false);
      saveProgress(activeFile, nextGroup, []);
    } else {
      setLearningCompleted(true);
    }
  };

  const shuffleLetters = () => {
    if (!currentWord) return;
    const word = language === 'EN-TR' ? currentWord.meaning : currentWord.word;
    const letters = word.split('').map((letter, index) => ({ letter, id: `${letter}-${index}` }));
    setShuffledLetters(shuffleArray([...letters]));
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleLetterClick = (letterId) => {
    const letterObj = shuffledLetters.find(l => l.id === letterId);
    if (letterObj) {
      setWritingAnswer([...writingAnswer, letterObj]);
      setShuffledLetters(shuffledLetters.map(l => 
        l.id === letterId ? { ...l, disabled: true } : l
      ));
    }
  };

  const handleClear = () => {
    shuffleLetters();
    setWritingAnswer([]);
  };

  const checkAnswer = () => {
    if (!currentWord) return;
    const word = language === 'EN-TR' ? currentWord.meaning : currentWord.word;
    if (writingAnswer.map(l => l.letter).join('') === word) {
      setScore(score + 1);
      nextWord();
    } else {
      alert('Yanlış cevap! Tekrar deneyin.');
    }
  };

  const resetGroups = () => {
    setReviewGroups([]);
    setCurrentGroup(0);
    setCurrentWordIndex(0);
    saveProgress(activeFile, 0, []);
  };

  const reviewGroup = (groupIndex) => {
    const group = reviewGroups.find(g => g.group === groupIndex);
    if (group) {
      setWords(group.words);
      setCurrentWordIndex(0);
      setCurrentGroup(groupIndex - 1);
      setLearnedWords([]);  // Bu satır, tekrar edilen grup için learnedWords'ü sıfırlamak için      
      setMode('learn');
      setLearningCompleted(false);
      setDisableLearnedButton(true);
    }
  };

  const studyWrongAnswers = () => {
    setWorkingWithWrongAnswers(true);
    setCurrentWordIndex(0);
    setMode('learn');
  };

  const clearWrongAnswers = () => {
    setWrongAnswers([]);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleShowHint = () => {
    setShowHint(!showHint);
  };

  const getHint = (word) => {
    if (showFullMeaning) return word;
    return showHint ? word.slice(0, 3) + '_'.repeat(word.length - 3) : '_'.repeat(word.length);
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}/register`, { username, password });
      setIsRegistering(false);
      setAlertMessage('Kayıt başarılı! Şimdi giriş yapabilirsiniz.');
      setAlertOpen(true);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      setAlertMessage('Kayıt başarısız: ' + errorMessage);
      setAlertOpen(true);
    }
  };
  
  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}/login`, { username, password });
      const token = `Bearer ${response.data.token}`;
      localStorage.setItem('token', token); // Token'ı local storage'a kaydedin
      setToken(token); // State'i de güncelleyin
      setIsLoggedIn(true);
      setAlertMessage('Giriş başarılı!');
      setAlertOpen(true);
      setUsername(username); // Kullanıcı adını state'e kaydedin
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      setAlertMessage('Giriş başarısız: ' + errorMessage);
      setAlertOpen(true);
    }
  };

  const handleDeleteFileClick = (fileName) => {
    setFileToDelete(fileName);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);
    if (!fileToDelete) return;
  
    try {
      await axios.delete(`${config.apiUrl}/files/${fileToDelete}`, {
        headers: { 'Authorization': token }
      });
      setUploadedFiles(uploadedFiles.filter(file => file.name !== fileToDelete));
      if (fileToDelete === activeFile) {
        setWords([]);
        setActiveFile(null);
        setLearnedWords([]);
        setReviewGroups([]);
        setCurrentGroup(0);
        setCurrentWordIndex(0);
        setScore(0);
        setMode('learn');
        setLearningCompleted(false);
      }
      setAlertMessage('Dosya başarıyla silindi.');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage('Dosya silme hatası.');
      setAlertOpen(true);
    } finally {
      setFileToDelete(null);
    }
  };
  
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setFileToDelete(null);
  };
  
  
  const handleFileInputReset = (event) => {
    event.target.value = null;  // Input değerini sıfırla
  };

  const logout = () => {
    localStorage.removeItem('token'); // Local storage'dan token'ı kaldır
    setToken(null);
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
    setWords([]);
    setUploadedFiles([]);
    setLearnedWords([]);
    setReviewGroups([]);
    setWrongAnswers([]);
    setActiveFile(null);
    setCurrentWordIndex(0);
    setCurrentGroup(0);
    setScore(0);
    window.location.reload(); // Sayfayı yenile
  };
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ padding: '16px', maxWidth: isMobile ? '100%' : '600px', margin: 'auto' }}>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton edge="start" color="inherit" aria-label="skor">
              <Badge badgeContent={score} color="secondary">
                <EmojiEvents />
              </Badge>
            </IconButton>
            <IconButton color="inherit" aria-label="öğrenilen kelime">
              <Badge badgeContent={`${learnedWords.length}/20`} color="secondary">
                <LibraryBooks />
              </Badge>
            </IconButton>
            <IconButton color="inherit" aria-label="grup">
              <Badge badgeContent={currentGroup + 1} color="secondary">
                <Book />
              </Badge>
            </IconButton>
            <IconButton color="inherit" aria-label="tüm öğrenilen kelimeler">
              <Badge badgeContent={globalLearnedWords} color="secondary">
                <LibraryBooks />
              </Badge>
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setLanguage('EN-TR')} color="inherit" style={{ margin: '0 8px' }}>
              <img src="/uk-flag.png" alt="EN-TR" style={{ width: '32px', height: '32px', borderRadius: '50%', border: '2px solid white' }} />
            </IconButton>
            <IconButton onClick={() => setLanguage('TR-EN')} color="inherit" style={{ margin: '0 8px' }}>
              <img src="/tr-flag.png" alt="TR-EN" style={{ width: '32px', height: '32px', borderRadius: '50%', border: '2px solid white' }} />
            </IconButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" onClick={logout}>
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>



      {!isLoggedIn ? (
        <Card>
          <CardHeader title={<Typography variant="h5" component="div" align="center">{isRegistering ? 'Kayıt Ol' : 'Giriş Yap'}</Typography>} />
          <CardContent>
            <TextField
              label="Kullanıcı Adı"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Şifre"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" color="primary" fullWidth onClick={isRegistering ? handleRegister : handleLogin}>
              {isRegistering ? 'Kayıt Ol' : 'Giriş Yap'}
            </Button>
            <Button variant="text" fullWidth onClick={() => setIsRegistering(!isRegistering)}>
              {isRegistering ? 'Giriş Yap' : 'Kayıt Ol'}
            </Button>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader title={
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h5" component="div">Kelime Yolculuğu</Typography>
              {activeFile && (
                <div style={{ marginTop: '8px' }}>
                        <Typography variant="caption" display="block" style={{ fontSize: '0.775rem', color: 'green' }}>
                          Aktif Dosya: {activeFile} ({uploadedFiles.find(file => file.name === activeFile)?.count} kelime) - Grup {currentGroup + 1}'de {learnedWords.length} öğrenildi.<br />
                          Toplam öğrenilen kelime sayısı {globalLearnedWords}
                        </Typography>
                </div>
              )}
            </div>
          } />


          <CardContent>
          {currentWord ? (
            <>
              <div style={{ marginBottom: '8px', textAlign: 'center' }}>
                <Typography variant="h6" component="span" style={{ fontWeight: 'bold' }}>
                  {language === 'EN-TR' ? currentWord.word : currentWord.meaning}
                </Typography>
                <Button onClick={() => speak(language === 'EN-TR' ? currentWord.word : currentWord.meaning)} style={{ marginLeft: '8px' }}>
                  <Headphones />
                </Button>
                <Button onClick={togglePlayPause}>
                  {isPlaying ? <Pause /> : <PlayArrow />}
                </Button>
                <Button onClick={toggleConversationMode} startIcon={isConversationMode ? <RecordVoiceOver /> : <VoiceOverOff />}>
                  {isConversationMode ? 'Conversation Off' : 'Conversation On'}
                </Button>
              </div>

              {mode === 'learn' && (
                <Card 
                style={{ 
                  height: '200px', 
                  overflow: 'auto', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  marginBottom: '8px',
                  cursor: 'pointer'  // İmleci pointer yaparak tıklanabilir olduğunu belirtiyoruz
                }}
                onClick={handleExpandClick}  // Tüm Card'a tıklama olayını ekliyoruz
              >
                <CardContent style={{ textAlign: 'center' }}>
                  {/* Tıklama durumuna göre gösterilecek içeriği değiştiriyoruz */}
                  {!expanded ? (
                    <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                      {getHint(language === 'EN-TR' ? currentWord.meaning : currentWord.word)}
                      <IconButton>
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '8px' }}>
                        {language === 'EN-TR' ? currentWord.meaning : currentWord.word}
                      </Typography>
                      <Typography variant="body1" style={{ marginTop: '8px' }}>
                        {language === 'EN-TR' ? currentWord.sentence : currentWord.sentenceMeaning}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px' }}>
                        {language === 'EN-TR' ? currentWord.sentenceMeaning : currentWord.sentence}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
              )}

              <div style={{marginBottom: '8px', textAlign: 'center' }}>                  
                <Button onClick={previousWord} variant="outlined" color="primary" disabled={learningCompleted}>
                  <ArrowBack />
                </Button>
                <Button onClick={markAsLearned} style={{ marginLeft: '15px' }} variant="outlined" color="success" disabled={learningCompleted || disableLearnedButton}>
                  <Check />
                </Button>
                <Button onClick={nextWord} style={{ marginLeft: '15px' }} variant="outlined" color="primary" disabled={learningCompleted}>
                  <ArrowForward />
                </Button>
              </div>

              <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <FormControlLabel
                  control={<Checkbox checked={showHint} onChange={toggleShowHint} />}
                  label="İpucu"
                />
                <FormControlLabel
                  control={<Checkbox checked={shuffleWords} onChange={() => {
                    setShuffleWords(!shuffleWords);
                    if (activeFile) {
                      fetchGroup(activeFile, currentGroup + 1);
                    }
                  }} />}
                  label="Rastgele"
                />
                <FormControlLabel
                  control={<Checkbox checked={showFullMeaning} onChange={() => setShowFullMeaning(!showFullMeaning)} />}
                  label="Tümü"
                />
              </div>

              <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => setMode('learn')} variant={mode === 'learn' ? 'contained' : 'outlined'} startIcon={<Book />}>
                  Öğren
                </Button>
                <Button 
                  variant={isQuizActive ? "contained" : "outlined"}
                  color="primary" 
                  onClick={startQuiz}
                  style={{ marginLeft: '8px' }}
                  disabled={words.length + learnedWords.length < 4}
                  >
                  Quiz
                </Button>
                <Button onClick={() => setMode('write')} variant={mode === 'write' ? 'contained' : 'outlined'} style={{ marginLeft: '8px' }}>
                  Yaz
                </Button>
              </div>

              {mode.includes('quiz') && (
                <div style={{ marginBottom: '16px' }}>
                  {quizOptions.map((option, index) => (
                    <Button key={index} onClick={() => handleQuizAnswer(option)} variant="outlined" fullWidth style={{ marginBottom: '8px' }}>
                      {option}
                    </Button>
                  ))}
                </div>
              )}

              {mode === 'write' && (
                <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                  <Typography align="center" variant="body1">
                    {writingAnswer.map(l => l.letter).join('')}
                  </Typography>
                  <div>
                    {shuffledLetters.map((letterObj) => (
                      <Button 
                        key={letterObj.id} 
                        onClick={() => handleLetterClick(letterObj.id)} 
                        variant="outlined" 
                        style={{ margin: '4px' }}
                        disabled={letterObj.disabled}
                      >
                        {letterObj.letter}
                      </Button>
                    ))}
                  </div>
                  <Button onClick={handleClear} variant="outlined" style={{ marginTop: '16px' }}>
                    Temizle
                  </Button>
                  <Button onClick={checkAnswer} variant="contained" style={{ marginTop: '16px', marginLeft: '8px' }}>
                    Kontrol Et
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Typography align="center" variant="body1">Yüklenecek kelime yok.</Typography>
          )}

            <Divider style={{ margin: '16px 0' }} />

            
            <Typography>Kelime Hedefi: {wordGoal} kelime</Typography>
            <LearnedProgressBar value={(learnedWords.length / wordGoal) * 100} />

            <Typography variant="h6" style={{ marginTop: '16px' }}>Öğrenilen Kelime Grupları</Typography>
            <List>
              {reviewGroups.filter(group => group.words.length === 20).map((group, index) => (
                <ListItem key={index} secondaryAction={
                  <Button variant="contained" onClick={() => reviewGroup(group.group)}>Tekrar Et</Button>
                }>
                  <ListItemText primary={`Grup ${group.group} (${group.words.length} kelime)`} />
                </ListItem>
              ))}
            </List>
            <Button variant="outlined" color="secondary" onClick={resetGroups} fullWidth>Listeleri Sıfırla</Button>

            <Divider style={{ margin: '16px 0' }} />

            <Typography variant="h6">Yüklenen Dosyalar</Typography>
            <List>
              {uploadedFiles.map((file, index) => (
                <ListItem key={index} secondaryAction={
                  <>
                    <Button variant="contained" onClick={() => selectFile(file)}>Seç</Button>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFileClick(file.name)}>
                      <Delete />
                    </IconButton>
                    <IconButton edge="end" aria-label="view" onClick={() => viewLearnedWords(file.name)}>
                      <Visibility />
                    </IconButton>
                    <IconButton edge="end" aria-label="reset" onClick={() => resetLearnedWords(file.name)}>
                      <Refresh />
                    </IconButton>
                  </>
                }>
                  <ListItemText primary={`${file.name}`} />
                </ListItem>
              ))}
            </List>            

            <Divider style={{ margin: '16px 0' }} />

            <Typography variant="h6">Hatalı Cevap Verilen Kelimeler: {wrongAnswers.length}</Typography>
            <List>
              {wrongAnswers.map((word, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${word.word} - ${word.meaning}`} />
                </ListItem>
              ))}
            </List>
            <Button variant="contained" color="primary" onClick={studyWrongAnswers} fullWidth>Sadece bu listedeki kelimeleri çalış</Button>
            <Button variant="outlined" color="secondary" onClick={clearWrongAnswers} fullWidth>Temizle</Button>

            
            <TextField type="file" onChange={handleFileUpload} onClick={handleFileInputReset} fullWidth style={{ marginTop: '16px' }} />
            <Divider style={{ margin: '16px 0' }} />
            <FormControlLabel
              control={<Checkbox checked={shuffleUpload} onChange={() => setShuffleUpload(!shuffleUpload)} />}
              label="Dosyadaki kelimeleri rastgele sırada içe aktar"
            />
            <Typography variant="caption" display="block" style={{ marginTop: '8px' }}>CSV veya TXT dosyası yükleyin (format: kelime;anlam;ingilizce cümle;Türkçe cümle)</Typography>
            
            


            
          </CardContent>
        </Card>
      )}

      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Devam Etmek İster misiniz?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Günlük 20 kelime tamamlandı. Devam etmek ister misiniz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Hayır
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Dosyayı Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu dosyayı sildiğinizde, öğrenilen kelimeler, gruplar ve puanlar silinecektir. Devam etmek istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            İptal
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="warning">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WordJourney;
