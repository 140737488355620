import React from 'react';
import './App.css';
import WordJourney from './components/WordJourney';

function App() {
  return (
    <div className="App">
      <WordJourney />
    </div>
  );
}

export default App;
